import React, { Component } from 'react';

import Navigation from './Navigation';
import CargarPuntos from './CargarPuntos'; 

export class Mapa extends Component {
    render() {
        return (
            <div id="ma" className=".container-fluid">
              <Navigation/> 
              <CargarPuntos />
            </div>
        )
    }
}

export default Mapa
