import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ProtectedRoute} from './protected.route'

import '../src/componentes/css/leaflet.css';
import '../src/componentes/css/bootstrap.min.css';
import '../src/componentes/css/mmenu.css';
import '../src/componentes/css/mburger.css';
import '../src/componentes/css/leaflet.css';
import '../src/componentes/css/style.css';

import Mapa from "./componentes/mapa/Mapa";
import Login from "./componentes/login/Login2";
//dashboard-1
import Dashboard from './componentes/dashboard/Dashboard';
import Lecturas from './componentes/dashboard/lecturas/Lecturas';
import Informacion from './componentes/dashboard/informacion/Informacion';
import Graficas from './componentes/dashboard/graficas/Graficas';
import Volumen from './componentes/dashboard/volumen/Volumen';
//admin
import ModSitios from './componentes/admin/modsitios/Sitios.js';
import ModEquipos from './componentes/admin/modequipos/Equipos.js';
import ModNorma from './componentes/admin/modNormaC/ConfNorC.js';


function App() {
  return (
    <Router>
    <Switch>
      <Route path="/" exact component={Login} />
      <ProtectedRoute path="/mapa" exact component={Mapa} />
      <ProtectedRoute path="/dashboard/1/:id" exact component={Dashboard} />
      <ProtectedRoute path="/lecturas/1/:id" exact component={Lecturas} />
      <ProtectedRoute path="/informacion/1/:id" exact component={Informacion} />
      <ProtectedRoute path="/graficas/1/:id" exact component={Graficas} />      
      <ProtectedRoute path="/volumen/1/:id" exact component={Volumen} />      
      <ProtectedRoute path="/admin/modsitios/" exact component={ModSitios} />
      <ProtectedRoute path="/admin/modequipos/" exact component={ModEquipos} />
      <ProtectedRoute path="/admin/modnormac/" exact component={ModNorma} />

      <Route path="*" component={()=>{"404 NOT FOUND"}}/>
      </Switch>
      </Router>
    
  );
}

export default App;
