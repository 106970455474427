import React, { Component } from "react";
import Navigation from "../Navigation";
import axios from "axios";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";
import GVolumen from "../volumen/GV1";
import GV2 from "../volumen/VBar";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});

export class Lecturas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: [],
      lecturasvol: [],
      fecha1: "",
      fecha2: "",
      sitio: "",
      nombre: "",
      contador: 1
    };
  }

  fecha1(event) {
    this.setState({ fecha1: event.target.value });
    console.log(event.target.value)
  }
  fecha2(event) {
    this.setState({ fecha2: event.target.value });
  }

   obtenerFechaActual() {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
   obtenerLunesDeEstaSemana() {
    const fechaActual = new Date();
    const diaDeLaSemana = fechaActual.getDay(); // 0 para domingo, 1 para lunes, ..., 6 para sábado
  
    // Calcular la diferencia de días para retroceder al lunes
    const diasHastaLunes = (diaDeLaSemana === 0 ? 6 : diaDeLaSemana - 1);
    fechaActual.setDate(fechaActual.getDate() - diasHastaLunes);
  
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
  obtenerFechaAnterior() {
    const fechaActual = new Date();
    fechaActual.setDate(fechaActual.getDate() - 1);
  
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
   obtenerPrimerDiaDelMes() {
    const fechaActual = new Date();
    fechaActual.setDate(1); // Establece el día al primero del mes
  
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

   obtenerPrimerDiaDelAnio() {
    const fechaActual = new Date();
    fechaActual.setMonth(0); // Establece el mes a enero
    fechaActual.setDate(1); // Establece el día al primero del mes
  
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  async cargarVolumenDia(){
    const fechaActual = this.obtenerFechaActual();
    const fechaAnterior = this.obtenerFechaActual();
    const res2 = await instance.get(
      process.env.REACT_APP_URL_MACHOTE +
        "volumen/fechas/" +
        this.state.sitio +
        "/" +
        fechaAnterior+
        "/" +
        fechaActual+
        "/0"
    );
    let lec = [];
    //console.log(res2.data)
    if (res2.data.length > 1) {
      for (var i = 0; i < res2.data.length; i++) {
        lec[i] = {
          fechalectura: res2.data[i].fechalectura,
          volumen: res2.data[i].volumen2
        };
      }
    }
    this.setState({ lecturasvol: lec });
  }

  async cargarVolumenSemana(){
    const fechaActual = this.obtenerFechaActual();
    const fechaAnterior = this.obtenerLunesDeEstaSemana();
    const res2 = await instance.get(
      process.env.REACT_APP_URL_MACHOTE +
        "volumen/fechas/" +
        this.state.sitio +
        "/" +
        fechaAnterior+
        "/" +
        fechaActual+
        "/0"
    );
    let lec = [];
    //console.log(res2.data)
    if (res2.data.length > 1) {
      for (var i = 0; i < res2.data.length; i++) {
        lec[i] = {
          fechalectura: res2.data[i].fechalectura,
          volumen: res2.data[i].volumen2
        };
      }
    }
    this.setState({ lecturasvol: lec });
  }


  async cargarVolumenMes(){
    const fechaActual = this.obtenerFechaActual();
    const fechaAnterior = this.obtenerPrimerDiaDelMes();
    const res2 = await instance.get(
      process.env.REACT_APP_URL_MACHOTE +
        "volumen/fechas/" +
        this.state.sitio +
        "/" +
        fechaAnterior+
        "/" +
        fechaActual+
        "/0"
    );
    let lec = [];
    //console.log(res2.data)
    if (res2.data.length > 1) {
      for (var i = 0; i < res2.data.length; i++) {
        lec[i] = {
          fechalectura: res2.data[i].fechalectura,
          volumen: res2.data[i].volumen2
        };
      }
    }
    this.setState({ lecturasvol: lec });
  }


  async cargarVolumenAnio(){
    const fechaActual = this.obtenerFechaActual();
    const fechaAnterior = this.obtenerPrimerDiaDelAnio();
    const res2 = await instance.get(
      process.env.REACT_APP_URL_MACHOTE +
        "volumen/fechas/" +
        this.state.sitio +
        "/" +
        fechaAnterior+
        "/" +
        fechaActual+
        "/0"
    );
    let lec = [];
    //console.log(res2.data)
    if (res2.data.length > 1) {
      for (var i = 0; i < res2.data.length; i++) {
        lec[i] = {
          fechalectura: res2.data[i].fechalectura,
          volumen: res2.data[i].volumen2
        };
      }
    }
    this.setState({ lecturasvol: lec });
  }
  async cargarlecturas() {
    const res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE +
        "lecturas1/fechas/" +
        this.state.sitio +
        "/" +
        this.state.fecha1 +
        "/" +
        this.state.fecha2
    );
    //console.log('https://api.instrutronica-iot.com/api/la/lecturas1/fechas/' + this.state.sitio+'/'+this.state.fecha1+'/'+this.state.fecha2);
    this.setState({ lecturas: res.data });

    const res2 = await instance.get(
      process.env.REACT_APP_URL_MACHOTE +
        "volumen/fechas/" +
        this.state.sitio +
        "/" +
        this.state.fecha1 +
        "/" +
        this.state.fecha2 +
        "/0"
    );
    let lec = [];
    console.log(res2.data)
    if (res2.data.length > 1) {
      for (var i = 0; i < res2.data.length; i++) {
        lec[i] = {
          fechalectura: res2.data[i].fechalectura,
          volumen: res2.data[i].volumen2
        };
      }
    }
    this.setState({ lecturasvol: lec });
  }

  async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    this.setState({ sitio: sit[5] });
    //console.log(sit);
    var res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "lecturas1/dr109/hoy/" + sit[5]
    );
    this.setState({ lecturas: res.data });
    res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "sitios/" + sit[5]
    );
    this.setState({ nombre: res.data[0].nombre });

    this.interval = setInterval(async () => {
      if (
        localStorage.getItem("the_main_app" + process.env.REACT_APP_COOKIE) ==
        null
      ) {
        this.props.history.push("/");
      }
    }, 1000);
  }



  render() {
    let count = 1;
    return (
      <div id="my-page">
        <div className="main-container bg-img">
          <Navigation activo="lecturas" />
          <section className="widget-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 page-title">
                  <h2>{this.state.nombre}</h2>
                </div>
              </div>
              <div className="content-area">
              <div className="row text-center ">
                    <div className="col-3 mb-1">
                      <button className="btn btn-info   btn-block" onClick={this.cargarVolumenDia.bind(this)}>
                        Volumen del Día
                      </button>
                    </div>
                    <div className="col-3 mb-1">
                      <button className="btn btn-info   btn-block" onClick={this.cargarVolumenSemana.bind(this)}>
                        Volúmenes Semana
                      </button>
                    </div>
                    <div className="col-3 mb-1">
                      <button className="btn btn-info   btn-block" onClick={this.cargarVolumenMes.bind(this)}>
                        Volúmenes del Mes
                      </button>
                    </div>{" "}
                    <div className="col-3 mb-1">
                      <button className="btn btn-info   btn-block" onClick={this.cargarVolumenAnio.bind(this)}>
                        Volúmenes del Año
                      </button>
                    </div>
                    <GV2
                      lecturas={this.state.lecturasvol}
                      contador={this.state.contador}
                    />
                    <GVolumen
                      lecturas={this.state.lecturasvol}
                      contador={this.state.contador}
                    />
                  </div>
                <div className="about-sec-2">  <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3>Revisa por fechas las lecturas</h3>
                    <form>
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <input
                            id="fecha1"
                            onChange={this.fecha1.bind(this)}
                            type="date"
                            className="form-control"
                            placeholder="Fecha Inicial"
                            aria-label="Fecha Inicial"
                            aria-describedby="button-addon4"
                          ></input>
                        </div>
                        <div className="col-md-4 mb-3">
                          <input
                            id="fecha2"
                            onChange={this.fecha2.bind(this)}
                            type="date"
                            className="form-control"
                            placeholder="Fecha Final"
                            aria-label="Fecha fianl"
                            aria-describedby="button-addon4"
                          ></input>
                        </div>
                        <div className="col-md-4 mb-3">
                          <button
                            className="btn  btn-primary"
                            type="button"
                            onClick={this.cargarlecturas.bind(this)}
                          >
                            Cargar Lecturas
                          </button>
                          <CSVLink
                            className="btn btn-info"
                            data={this.state.lecturas}
                            asyncOnClick={true}
                            filename={
                              "" +
                              this.state.nombre +
                              "-" +
                              this.state.fecha2 +
                              "-reporte.csv"
                            }
                          >
                            Descargar CSV
                          </CSVLink>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-12">
                  <div className="alert alert-warning" role="alert">
                  ⚠️ No solicite más de 3 meses de lecturas, por la cantidad de lecturas podría experimentar una sensación de lentitud en la plataforma, si requiere descargar las lecturas hágalo en intervalos de 3 meses.
</div>
                  </div>
                </div>
                
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Caudal</th>
                            <th>Volumen</th>
                            <th>Nivel</th>
                            <th>Velocidad</th>
                            <th>Fecha</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.lecturas.map(function (lectura) {
                            return (
                              <tr key={lectura._id}>
                                <th scope="row">{count++}</th>

                                <td>
                                  {lectura.caudal === null
                                    ? "null"
                                    : lectura.caudal.toFixed(3)}
                                </td>
                                <td>
                                  {lectura.volumen2 === null
                                    ? "null"
                                    : lectura.volumen2.toFixed(3)}
                                </td>
                                <td>
                                  {lectura.volumen === null
                                    ? "null"
                                    : lectura.volumen.toFixed(3)}
                                </td>
                                <td>
                                  {lectura.batm === null
                                    ? "null"
                                    : lectura.batm.toFixed(3)}
                                </td>
                                <td>
                                  {!lectura.fechalec
                                    ? lectura.fechalectura
                                    : lectura.fechalec}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Lecturas);
