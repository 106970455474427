import React, { Component } from "react";
import axios from "axios";
import Navigation from "./Navigation";
import Page from "./overview/Page";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [
        {
          nombresitio: [
            {
              _id: "5de77233e4c95d1640c0c077",
              nombre: "ACTUALIZANDO"
            }
          ]
        },
        {
          lecturas: [
            {
              _id: "0",
              caudal: null,
              volumen: null,
              fechalec: "null",
              fechalectura: "2019-01-01T00:00:00.000Z",
              estatus: "No hay Datos",
              senal: null,
              temp_equipo: null,
              bateria: null
            }
          ]
        },
        {
          ultimalectura: [
            {
              _id: "0",
              caudal: null,
              volumen: null,
              fechalec: "null",
              fechalectura: "2019-01-01T00:00:00.000Z",
              estatus: "No hay Datos",
              senal: null,
              temp_equipo: null,
              bateria: null
            }
          ]
        },
        {
          volmes: 0
        },
        {
          volsem: 0
        },
        {
          voldia: 0
        },
        {
          estatus: "Actualizando"
        }
      ],
      contador: 1,
      sitio: ""
    };
  }

  async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    const res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "overview1/dr109/" + sit[5]
    );
    console.log(res.data);
    this.setState({ datos: res.data }, () => {
      this.setState({ contador: this.state.contador++ }, () => {
        this.render();
      });
    });
    this.setState({ sitio: sit[5] });

    this.interval = setInterval(async () => {
      var d = new Date();
      var n = d.getMinutes();
      var seg = d.getSeconds();
      if (
        localStorage.getItem("the_main_app" + process.env.REACT_APP_COOKIE) ==
        null
      ) {
        this.props.history.push("/");
      }
      //console.log(seg);
      if (n === 1 || n === 11 || n === 21 || n === 31 || n === 41 || n === 51) {
        if (seg === 0) {
          const res = await instance.get(
            process.env.REACT_APP_URL_MACHOTE + "overview1/dr109/" + sit[5]
          );
          this.setState({ datos: res.data }, () => {
            this.setState({ contador: this.state.contador++ }, () => {
              this.render();
            });
          });
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    //console.log(this.state.datos);
    return (
      <div id="my-page">
        <div className="main-container bg-img">
          <Navigation activo="overview" />
          <Page lecturas={this.state.datos} contador={this.state.contador} />
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
